import merge from 'deepmerge'
import { Formik, getIn } from 'formik'
import { DownloadManager } from 'pdfjs-dist/web/pdf_viewer.js'
import PropTypes from 'prop-types'
import React from 'react'
import isEqual from 'react-fast-compare'
import { Document, Page, pdfjs } from 'react-pdf'

import log from '../logging'
import { hasAddons, hasPermission, isConditional, overwriteMerge, parseURL, uniqueArray, sortBy, groupBy } from '../utils'
import validate from '../validate'
import Loader from './common/Loader'
import Card from './common/Card'
import CustomForm from './common/forms/CustomForm'
import FieldGroup from './common/forms/FieldGroup'
import ModelActions from './common/ModelActions'
import QueryBuilder from './common/QueryBuilder'
import { Button } from './ui/Button'


// pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`
pdfjs.GlobalWorkerOptions.workerSrc = `https://unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`

class BrandAssetGenerator extends React.Component {
  constructor(props) {
    super(props)
    const qs = new QueryBuilder(location.search)
    this.state = {
      offset: 0,
      initvals: false,
      default: null,
      pageNumber: 1,
      page: 1,
      numPages: 0,
      scrolling: false,
      document: null,
      width: null,
      height: null,
      template_id: qs.getParam('template'),
      template_config: null,
      template_cache: {},
      url: '',
      status: ''
    }
    this.isConditional = isConditional.bind(this)
    this.hasPermission = this.hasPermission.bind(this)
    this.redirectSchema = this.redirectSchema.bind(this)
    this.addVerb = this.addVerb.bind(this)
    this.onDocumentLoadSuccess = this.onDocumentLoadSuccess.bind(this)
    this.goToPrevPage = this.goToPrevPage.bind(this)
    this.goToNextPage = this.goToNextPage.bind(this)
    this.isEnter = this.isEnter.bind(this)
    this.goToPage = this.goToPage.bind(this)
    this.download = this.download.bind(this)
    this.downloadImage = this.downloadImage.bind(this)
    this.onMouseDown = this.onMouseDown.bind(this)
    this.onMouseUp = this.onMouseUp.bind(this)
    this.onMouseMove = this.onMouseMove.bind(this)
    this.downloadManager = new DownloadManager({
      disableCreateObjectURL: false
    })
    this.fetchTemplateConfig = this.fetchTemplateConfig.bind(this)
    this.findFields = this.findFields.bind(this)
  }

  componentDidMount() {
    // this.props.actions.selectNone()
    if (this.props.modelid) {
      this.props.fetchOne(this.props.modelname, this.props.modelid, false, false, true)
    }
    if (!this.hasPermission()) { // If we're not allowed to be here, redirect to designated redirect
      const redirect = parseURL(getIn(this.props.routeConfig, 'brochure.redirect', getIn(this.props.routeConfig, 'letter.redirect')), this.props.model)
      this.props.registerRedirect(redirect)
    }
    this.fetchTemplateConfig()
  }

  componentDidUpdate(prevProps) {
    const { location, modelname } = this.props
    let initial_ids = []
    let model = 0
    let old_model = 0
    if (this.props.modelid !== prevProps.modelid) {
      this.props.fetchOne(this.props.modelname, this.props.modelid, false, false, true)
    }
    if (location.search) {
      const qs = new QueryBuilder(location.search)
      if (qs.hasParam('id__in')) { initial_ids = initial_ids.concat(qs.getParam('id__in')) }
      if (initial_ids.length) {
        model = this.props.cache[modelname][initial_ids[0]]
        old_model = prevProps.cache[modelname][initial_ids[0]]
      }
    }
    if (
      (Object.keys(this.state.template_cache).length
      && !this.state.initvals
      && this.props.model)
      || !isEqual(this.props.model, prevProps.model)
    ) { // Model has loaded after mount
      this.setState({ initvals: this.initModel(this.state.template_config, this.props.model) })
      this.findFields(this.state.template_config)
    }
    if (!isEqual(model, old_model)) {
      this.setState({ initvals: this.initModel(this.state.template_config, model) })
      this.findFields(this.state.template_config)
    }
    const tab_el = document.querySelector('.viewcontent')
    if (tab_el) {
      const offset = tab_el.getBoundingClientRect().top
      if (this.state.offset !== offset) {
        this.setState({ offset })
      }
    }
  }

  findFields(fields) {
    const { template_config } = this.state
    const { modelname } = this.props
    const { fields: model_fields } = this.props.config
    let brochure_config = { ...template_config }
    // Merge field configs from model fields and brochure fields.
    // Deepmerge wasn't doing this correctly.
    if (!template_config || !template_config.fields) {
      if (fields?.fields) {
        brochure_config = { ...brochure_config, ...fields }
      } else {
        brochure_config = { fields: [] }
      }
    }
    const all_fields = merge([], model_fields, { arrayMerge: overwriteMerge }).map(f => {
      const override = brochure_config.fields.find(bf => bf.name === f.name)
      if (override) { return merge(f, override, { arrayMerge: overwriteMerge }) }
      return f
    })
    const section_fields = brochure_config.fields.filter(f => f.section)
    const default_fields = all_fields.filter(f => f.edit)
    const main_fields = modelname === 'seller-feedback' ? brochure_config.fields.filter(f => !f.section) : [ ...brochure_config.fields.filter(f => !f.section), ...default_fields ]

    const sectioned_fields = groupBy(section_fields, 'section')
    const sections = Object.keys(sectioned_fields).map(s => ({
      value: s,
      label: s
    }))
    Object.keys(sectioned_fields).map(section => {
      sectioned_fields[section] = [ ...[
        {
          name: 'section',
          label: 'Section',
          input: 'Select',
          defaultvalue: sections.length ? sections[0].value : null,
          options: sections,
          edit: true
        }
      ], ...sectioned_fields[section] ]
    })
    const fields_to_fetch = brochure_config.fields.filter(f => f.fetch)
    if (fields_to_fetch.length && this.props.model) {
      const defaults = fields_to_fetch.map(field =>
        new Promise((resolve, reject) => this.props.actions.fetchFieldDefault({
          field,
          template: brochure_config.name,
          modelname: this.props.config.servicename,
          id: this.props.model.id
        }, resolve, reject)))
      Promise.allSettled(defaults).then(values => {
        const updated_values = this.form ? this.form.values : this.state.initvals
        fields_to_fetch.forEach((f, fid) => {
          if (getIn(values, `${fid}.value.defaultvalue`)) {
            updated_values[f.name] = getIn(values, `${fid}.value.defaultvalue`)
          }
        })
        this.setState({
          sectioned_fields,
          main_fields,
          sections,
          template_config: brochure_config,
          all_fields: uniqueArray(main_fields, 'name'),
          initvals: this.initModel(brochure_config, {
            ...updated_values,
            section: sections.length ? sections[0].value : null
          })
        })
      })
    } else {
      const updated_values = this.form ? this.form.values : this.state.initvals
      this.setState({
        sectioned_fields,
        main_fields,
        sections,
        template_config: brochure_config,
        all_fields: uniqueArray(main_fields, 'name'),
        initvals: this.initModel(brochure_config, {
          ...updated_values,
          section: sections.length ? sections[0].value : null
        })
      })
    }
  }

  hasPermission() {
    const { model, user, config, addons } = this.props
    if (config.addons) {
      if (!hasAddons(config.addons, addons)) { return false } // Entire module is disabled
    }
    const requiredPermissions = getIn(this.props.routeConfig, 'brochure.permissions', getIn(this.props.routeConfig, 'letter.permissions'))
    if (user.permissions.includes('is_prop_data_user')) { return true }
    if (!requiredPermissions) { return true } // No permissions needed
    const hasEditOwnPermissions = requiredPermissions.filter(perm => perm.endsWith('_view_own'))
    const hasEditAllPermissions = requiredPermissions.filter(perm => perm.endsWith('_view'))
    if (this.props.model && this.props.modelid) {
      if (hasPermission(hasEditAllPermissions, user.permissions)) { return true }
      if (hasPermission(hasEditOwnPermissions, user.permissions)) {
        if (model.agent !== user.agent.id) { return false }
        return true
      }
    }
    if (hasPermission(requiredPermissions, user.permissions)) { return true } // Implicit permissions
    return false
  }

  initModel(template_config, values) { // Initialise model data for formik
    const initvals = {}
    const { model, cache, config, location, modelname } = this.props
    let data = { ...model }
    let initial_ids = []
    if (location.search) {
      const qs = new QueryBuilder(location.search)
      if (qs.hasParam('id__in')) { initial_ids = initial_ids.concat(qs.getParam('id__in')) }
      if (initial_ids.length) {
        data = cache[modelname][initial_ids[0]]
      }
    }
    if (modelname === 'deals') {
      data = { ...data, ...this.state.initvals, ...values }
    }
    // Initialise template options
    const template_list = sortBy(Object.keys(this.state.template_cache).map(k => this.state.template_cache[k]), 'ordinal')
    initvals.template = getIn(template_config, 'name', getIn(template_list, '0.name'))
    const brochure_config = template_config
    if (brochure_config) {
      const brochure_fields = brochure_config.fields?.map(f => {
        const field = config.fields.find(fe => fe.name === getIn(f, 'name', f).replace('override-', ''))
        if (field) {
          return merge(f, field)
        }
        return f
      })
      if (brochure_config) {
        if (!isEqual(this.state.template_config, template_config)) {
          this.setState({ template_config })
        }
        brochure_config.fields?.forEach(f => {
          const name = getIn(f, 'name', f)
          const value = getIn(data, name) || getIn(data, `override-${name}`)
          initvals[`override-${name}`] = value
          if (f.input === 'Check') {
            initvals[`override-${name}`] = false
          }
        })
      }
      Object.keys(initvals).forEach(f => {
        const field = brochure_fields?.find(fe => fe.name === f.replace('override-', ''))
        if (!field) { return }

        if (field.name === 'template' && [ 'window-display-portrait', 'window-display-landscape', 'property-information' ].includes(initvals[f])) {
          return
        }
        if (field && field.modelname) {
          if (field.extraparams) { // Used for form submission
            field.params = parseURL(
              field.extraparams,
              data,
              this.props.user ? this.props.user.agent : false,
              this.props.user ? this.props.cache.settings[this.props.user.agent.site.id] : false,
              this.props.cache,
              0, // index
              field.extraparamslock
            )
          }
          if (field.params) {
            const qs = new QueryBuilder(field.params)
            const params = qs.getAllArgs()
            params[`${field.optionvalue ? field.optionvalue : 'id'}__in`] = Array.isArray(initvals[f]) ? initvals[f] : [ initvals[f] ]
            const vals = {
              modelname: field.modelname,
              params
            }
            this.props.actions.fetchMany({ values: vals })
          }
        }
      })

      if (initial_ids.length) {
        Object.keys(initvals).forEach(f => {
          if (![ 'template', 'id__in' ].includes(f) || [ 'listing_images' ].includes(f.replace('override-', ''))) {
            delete initvals[`override-${f.replace('override-', '')}`]
          }
          if ([ 'agent', 'agent_2', 'agent_3', 'agent_4' ].includes(f.replace('override-', ''))) {
            initvals[`override-${f.replace('override-', '')}`] = 'default'
          }
        })
      }
      return initvals
    }
    return {}
  }

  fetchTemplateConfig() {
    if (!this.state.template_id) { return }
    this.setState({ fetching: true })
    new Promise((resolve, reject) => this.props.actions.fetchTemplateConfig({
      params: {
        template_type: 'brand-assets',
        template: this.state.template_id
      },
      resolve,
      reject
    })
    ).then(r => {
      this.setState({
        initvals: this.initModel({ ...this.state.template_config, ...r }, { ...this.form.values }),
        fetching: false
      }, () => {
        this.findFields(r)
      })
    }).catch(e => {
      console.error(e)
    })
  }

  async downloadImage() {
    const { cache, modelname } = this.props
    const { template_config } = this.state
    const id = this.form.values.id__in[0]
    const filename = `${cache[modelname][id].web_ref} - ${template_config.display_name} - ${this.state.page}.png`
    try {
      return await this.state.document.getPage(this.state.page).then(page => {
        const scale = '1.5'
        const viewport = page.getViewport({
          scale: scale
        })
        const canvas = document.createElement('canvas')
        const canvasContext = canvas.getContext('2d')
        canvas.height = viewport.height || viewport.viewBox[3] /* viewport.height is NaN */
        canvas.width = viewport.width || viewport.viewBox[2] /* viewport.width is also NaN */
        page.render({
          canvasContext, viewport
        }).promise.then(() => {
          canvas.toBlob(blob => {
            this.downloadManager.download(blob, canvas.toDataURL(), filename)
          })
        })
      }).catch(e => {
        console.error(e)
      })
    } catch (e) {
      console.error(e)
    }
    return null
  }

  async download(event, id) {
    const { cache, modelname } = this.props
    const { template_config } = this.state
    const pdfDoc = this.state.document
    const { url } = pdfDoc._transport._params
    const filename = `${cache[modelname][id].web_ref} - ${template_config.display_name}.pdf`

    const downloadByUrl = () => {
      this.downloadManager.downloadUrl(url, filename)
    }

    try {
      const data = await this.state.document.getData()
      const blob = new Blob([ data ], { type: 'application/pdf' })
      this.downloadManager.download(blob, url, filename)
    } catch (e) {
      downloadByUrl()
    }
  }

  redirectSchema(schema) { this.setState({ redirect: schema }) }

  addVerb(bag, sections) {
    const { fields } = this.props.config
    const template_fields = getIn(this.state, 'template_config.fields', []) || []
    const inferred_fields = template_fields.map(f => {
      if (typeof f === 'string') {
        return fields.find(field => field.name === f)
      }
      const real_field = fields.find(field => isEqual(field.name, f.name))
      if (real_field) {
        return real_field
      }
      return f
    })
    const all_fields = uniqueArray(merge([], [
      ...fields,
      ...inferred_fields
    ], { arrayMerge: overwriteMerge }).map(f => f), 'name')

    if (all_fields) {
      let brochure_fields = all_fields.filter(f => {
        if (!f) { return false }
        if (sections) {
          if (f.section && f.section === getIn(bag, 'values.section')) {
            return true
          }
          return false
        }
        if (f.name === 'model' && this.props.modelname) { return false }
        if (f.name === 'id__in' && bag.values.id__in && !this.props.modelid) { return true }
        if (f.name === 'template' && (this.props.modelid || bag.values.id__in)) { return true }
        if (f.section) { return false }
        if (this.state.template_config?.fields) {
          return this.state.template_config.fields.map(field => getIn(field, 'name', field)).includes(f.name)
        }
        return false
      })

      if (sections) {
        const sectionfield = all_fields.find(f => f.name === 'section')
        if (sectionfield) {
          sectionfield.edit = true
          sectionfield.defaultvalue = this.state.sections.length ? getIn(this.state, 'section') || this.state.sections[0].value : null
          sectionfield.options = this.state.sections
          brochure_fields.unshift(sectionfield)
        }
      }

      brochure_fields = brochure_fields.map(field => {
        const rest = this.state.template_config ? this.state.template_config.fields?.find(f => getIn(f, 'name') === field.name) || {} : {}
        const props = {
          ...field,
          ...rest,
          cols: 'lg-12',
          required: field.name === 'template' || field.required,
          quality: false,
          name: `${(this.state.template_config && this.state.template_config.fields?.map(f => getIn(f, 'name', f)).includes(field.name)) || [ 'agent', 'agent_2', 'agent_3', 'agent_4' ].includes(field.name.replace('override-', '')) ? `override-${field.name}` : field.name}${field.verb ? `__${field.verb}` : ''}`
        }
        return props
      })

      return <FieldGroup
        form={bag}
        card={true}
        groupname="Brand Asset Details"
        match={this.props.match}
        modelid={this.props.modelid}
        config={{
          fields: brochure_fields
        }}
        fields={brochure_fields}
      />
    }
    return null
  }

  onDocumentLoadSuccess(document) {
    const { numPages } = document
    document.getPage(1).then(page => {
      const viewport = page.getViewport()
      return { width: viewport.viewBox[2], height: viewport.viewBox[3] }
    }).then(({ width, height }) => {
      this.setState({
        scale: 1.0,
        rotate: null,
        zoom: 'auto',
        scrolling: false,
        highlight: false,
        numPages,
        page: 1,
        pageNumber: 1,
        document,
        width: width,
        height: (this.el.scrollWidth * (height / width)) + 25
      })
    })
  }

  goToPrevPage() {
    const newpage = parseInt(this.state.pageNumber, 10) - 1
    if (newpage > 0) {
      this.setState({ pageNumber: newpage, page: newpage })
    }
  }

  goToNextPage() {
    const newpage = parseInt(this.state.pageNumber, 10) + 1
    if (newpage <= this.state.numPages) {
      this.setState({ pageNumber: newpage, page: newpage })
    }
  }

  goToPage(page) {
    const newpage = page
    if (newpage <= this.state.numPages && newpage > 0) {
      this.setState({ pageNumber: newpage })
    }
  }

  onMouseDown(event) {
    if (!this.el) { return false }
    const { scrollLeft, scrollTop } = this.el
    this.setState({ scrolling: true, scrollLeft, scrollTop, clientX: event.clientX, clientY: event.clientY })
    return event
  }

  onMouseMove(event) {
    if (!this.el) { return false }
    const { clientX, scrollLeft, scrollTop, clientY } = this.state
    this.el.scrollLeft = scrollLeft - clientX + event.clientX
    this.el.scrollTop = scrollTop - clientY + event.clientY
    return event
  }

  onMouseUp(event) {
    if (!this.el) { return false }
    const { scrollLeft, scrollTop } = this.el
    this.setState({
      scrolling: false,
      scrollLeft,
      scrollTop,
      clientX: event.clientX,
      clientY: event.clientY
    })
    return event
  }

  isEnter(e) {
    if (e.keyCode === 13) { // fire goToPage on enter
      return this.goToPage(e.target.value)
    } // continue typing
    return true
  }

  render() {
    const { config, actions, ui, location } = this.props
    let initial_ids = [ this.props.modelid ].filter(a => a)
    if (location.search) {
      const qs = new QueryBuilder(location.search)
      if (qs.hasParam('id__in')) { initial_ids = initial_ids.concat(qs.getParam('id__in')) }
    }
    initial_ids = initial_ids.filter(k => !Number.isNaN(parseInt(k, 10)))

    return (
      <Formik
        validationSchema={validate.brochure_generator}
        validateOnChange={true}
        initialValues={{
          ...this.state.initvals,
          model: config.servicename || this.props.modelname,
          id__in: initial_ids
        }}
        enableReinitialize={true}
        onSubmit={(values, form) => new Promise((resolve, reject) => {
          form.setSubmitting(true)
          const { template, ...cleanvalues } = values
          cleanvalues.id__in = cleanvalues.id__in.join(',')
          delete cleanvalues.offset
          const params = {
            params: cleanvalues,
            modelname: this.props.modelname,
            args: {
              action: 'brochure',
              template
            },
            label: 'Brochure',
            noalert: true,
            callback: this.setStatus,
            resolve,
            reject
          }
          this.setState({ url: '' })
          return actions.exportData(params)
        }).then(r => {
          form.setSubmitting(false)
          const url = r.response.file
          form.setSubmitting(false)
          this.setState({ url })
        }).catch(e => {
          if (Object.keys(e).includes('id__in') || Object.keys(e).includes('template')) {
            form.setErrors(e.response)
          }
          form.setSubmitting(false)
          log.error(e)
        })
        }
      >{formik => {
          this.form = formik
          return (
            <div id="content" className="content">
              <div className="viewhead details">
                <div className="action-bar">
                  <ModelActions
                    touched={formik.touched}
                    errors={formik.errors}
                    handleSubmit={e => {
                      this.download(e, this.form.values.id__in[0])
                    }}
                    isSubmitting={formik.isSubmitting}
                    redirectSchema={this.redirectSchema}
                    form={formik}
                    modelname={config.modelname}
                    actions={{
                      downloadImage: this.downloadImage
                    }}
                    extras={{
                      savepng: {
                        label: 'Download PNG',
                        menu: null,
                        redirect: null,
                        icon: '#icon16-Download',
                        action: 'downloadImage',
                        className: 'btn-round btn-red',
                        routes: [ 'brochure' ]
                      },
                      save: {
                        label: 'Generate Brochure',
                        menu: null,
                        redirect: null,
                        icon: '#icon16-Download',
                        action: 'submitForm',
                        className: 'btn-round btn-red',
                        routes: [ 'brochure' ]
                      }
                    }}
                    statusmsg={formik.status ? formik.status.msg : false}
                  />
                </div>
              </div>
              <div className={`view details brochures ${config.modelname}`}>
                <div className="viewcontent">
                  <div className="brochure-generator">
                    <div className='brochure-form'>
                      <CustomForm
                        ui={ui}
                        model={this.props.model ? true : false}
                        setContextForm={this.props.actions.setContextForm}
                        render={() => {
                          if (this.state.sections) {
                            return <>
                              <div className='row'>
                                <div className='col'>
                                  {this.addVerb(formik)}
                                </div>
                              </div>
                              <div className='row'>
                                <div className='col'>
                                  {this.addVerb(formik, true)}
                                </div>
                              </div>
                            </>
                          }
                          return (
                            <div className='row'>
                              <div className='col'>
                                {this.addVerb(formik)}
                              </div>
                            </div>
                          )
                        }}
                      />
                    </div>
                    <div className='brochure-preview'>
                      <Card
                        background={true}
                        header={
                          <h3>Brand Asset Preview</h3>
                        }
                        body={
                          <div className='pdfcontainer'>
                            {this.state.url ? (
                              <nav id='pdfcontainer-nav' className='pdfcontainer-nav'>
                                <div className="nav-group">
                                  <div className="pagejump">
                                    <div className="form-group page">
                                        Page
                                      <div className="forminput">
                                        <input
                                          // component="input"
                                          type="number"
                                          className="form-control form-control-lg page-jump"
                                          min={1}
                                          value={this.state.page}
                                          onKeyDown={this.isEnter}
                                          onChange={e => this.setState({ page: e.target.value })}
                                        />
                                      </div>
                                        /  {this.state.numPages}
                                    </div>
                                  </div>
                                  <Button icon="#icon16-ChevronLeft" title="Previous Page" className="btn btn-subtle btn-icon-16 btn-icon-only" type='button' disabled={this.state.page <= 1} onClick={this.goToPrevPage} />
                                  <Button icon="#icon16-ChevronRight" title="Next Page" className="btn btn-subtle btn-icon-16 btn-icon-only" type='button' disabled={this.state.page === this.state.numPages} onClick={this.goToNextPage} />
                                </div>
                                <Button icon="#icon16-EyeOpen" title="Preview" className="btn btn-primary btn-icon-16 btn-icon-left" type='button' onClick={formik.handleSubmit}>{(formik.dirty && Object.keys(formik.touched).length) ? 'Update Preview' : 'Preview'}</Button>
                              </nav>
                            ) : null }
                            <div ref={el => { if (el) { this.el = el } }} className={`react-pdf${this.state.highlight ? ' react-pdf__Highlighting' : ''}${(!this.state.highlight && this.state.scrolling) ? ' react-pdf__Scrolling' : ''}`} style={{ height: this.state.height }}>
                              {formik.isSubmitting ? (
                                <div className="text-center">
                                  <Loader inline className="large" onError={() => {}} throwViewError={() => {}} />
                                  <div className="message">{this.state.status ? this.state.status.response.detail : null}</div>
                                </div>
                              ) : (
                                <Document
                                  file={this.state.url}
                                  loading={<Loader inline className="large" onError={() => {}} throwViewError={() => {}} />}
                                  onLoadSuccess={this.onDocumentLoadSuccess}
                                  onMouseDown={this.onMouseDown}
                                  onScroll={this.onMouseMove}
                                  rotate={this.state.rotate}
                                  noData={(
                                    <div className="no-pdf">
                                      <Button icon="#icon16-EyeOpen" title="Preview" className="btn btn-primary btn-icon-16 btn-icon-left" type='button' onClick={formik.handleSubmit}>Preview PDF</Button>
                                    </div>
                                  )}
                                >
                                  <Page
                                    pageNumber={parseInt(this.state.pageNumber, 10)}
                                    renderTextLayer={this.state.highlight}
                                    scale={this.state.scale}
                                  />
                                </Document>
                              )}
                            </div>
                          </div>
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )
        } }
      </Formik>
    )
  }
}

BrandAssetGenerator.propTypes = {
  model: PropTypes.object,
  location: PropTypes.object,
  fetchOne: PropTypes.func,
  modelname: PropTypes.string,
  modelid: PropTypes.string,
  routeConfig: PropTypes.object,
  actions: PropTypes.object,
  cache: PropTypes.object,
  configs: PropTypes.object,
  config: PropTypes.object,
  match: PropTypes.object,
  ui: PropTypes.object,
  user: PropTypes.object,
  addons: PropTypes.array,
  registerRedirect: PropTypes.func
}

export default BrandAssetGenerator
